@import-normalize;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.4;
  color: #333333;
  -webkit-font-smoothing: antialiased;
}
